<template>
  <div>
    <navbar title="支付设置"></navbar>
    <div>
      <div class="cell" v-if="passStatus == 0" @click="setPass(0)">
        <div class="label">设置支付密码</div>
        <van-icon name="arrow" color="#666666" :size="$px2vw(26)" />
      </div>
      <div class="cell" v-else @click="setPass(1)">
        <div class="label">修改支付密码</div>
        <van-icon name="arrow" color="#666666" :size="$px2vw(26)"/>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import {userDetail} from '@/api/index'

export default {
  name: 'setting',
  components: {
    Navbar
  },
  data() {
    return {
      passStatus: 0
    }
  },
  created() {
    this.getPassStatus()
  },
  methods: {
    setPass(type) {
      this.$router.push({name: 'setPassword', query: {type: type}})
    },
    getPassStatus() {
      userDetail({type: 'password'})
        .then(res => {
          if(res.code == 0) {
            this.passStatus = res.data
          }
        }).catch(err => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.cell{
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  .label{
    font-size: 30px;
    font-weight: bold;
    color: #666666;
  }
}
</style>